import { twMerge } from 'tailwind-merge'

export interface GridProps {
  cols: number
  className?: string
  children: React.ReactNode
}

export function Grid({ cols, className = '', children }: GridProps) {
  return <div className={twMerge(`grid grid-cols-${cols}`, className)}>{children}</div>
}

export default Grid
