import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Theme, ToggleButton } from '@mui/material'
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material'
import { useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { NoOp } from '@unreserved-frontend-v2/utils/noop'
import { ReactFunctionalComponent } from '@unreserved-frontend-v2/utils/types'

import { useTailwindConfig } from '../tailwind/use-tailwind-config'
import ToggleButtonIcon from '../toggle-button-icon/toggle-button-icon'

export interface UiToggleButtonGroupProps extends ToggleButtonGroupProps {
  /** Buttons to be rendered for selection */
  items: ToggleButtonItem[]
  /** if true, toggle button can exceed 40px height */
  dynamicHeight?: boolean
  /** if true, it sets a grid of two columns */
  tileGrid?: boolean
  /** classes to apply to each item */
  itemClass?: string
  /** if specified, toggle will be prefixed with a non-selectable icon */
  iconStart?: IconProp
  /** True if the group is in an error state. */
  isError?: boolean
  /** Tailwind classes passed to the main container. */
  containerClassName?: string
  /** True if we are to make the right side of the last button have square borders. */
  squareBordersOnLastButton?: boolean
  /** True if the group allows unselecting the currently selected value. */
  allowUnselectCurrentValue?: boolean
}

export interface ToggleButtonItem {
  label?: string
  value?: string
  icon?: IconProp | ReactFunctionalComponent
  iconType?: 'icon' | 'svg'
  className?: string
  iconClassName?: string
  svgClassName?: string
  dataTestId?: string
}

/**
 * @deprecated As this uses MUI
 */
export function UiToggleButtonGroup({
  items,
  dynamicHeight,
  tileGrid,
  itemClass,
  iconStart,
  isError = false,
  containerClassName = '',
  squareBordersOnLastButton = false,
  onChange = NoOp,
  allowUnselectCurrentValue = true,
  ...rest
}: UiToggleButtonGroupProps) {
  const { primary } = useTailwindConfig()

  const onChangeInternal = useCallback(
    (_: React.MouseEvent<HTMLElement>, newValue: string) => {
      if (allowUnselectCurrentValue || newValue !== null) {
        onChange(_, newValue)
      }
    },
    [allowUnselectCurrentValue, onChange]
  )

  return (
    <div className={twMerge('flex', containerClassName)}>
      {iconStart ? (
        <div
          className={twMerge(
            `h-[40px] rounded-l border border-r-0 border-shades-200 bg-shades-25 px-2 py-2 text-center ${
              itemClass ?? ''
            }`
          )}
        >
          <FontAwesomeIcon
            data-testid="toggle-icon-start"
            icon={iconStart}
            className="text-shades-600"
            transform={{ y: -2, x: -1 }}
          />
        </div>
      ) : null}
      <ToggleButtonGroup
        color={`${isError ? 'error' : 'primary'}`}
        exclusive={true}
        sx={tileGrid ? { background: 'transparent !important' } : {}}
        {...rest}
        onChange={onChangeInternal}
        className={twMerge(tileGrid ? 'grid grid-cols-2 gap-4' : '', rest.className ?? '')}
      >
        {items.map((item) => {
          return (
            <ToggleButton
              key={item.value}
              sx={{
                color: `${isError ? 'error.main' : 'grey.800'}`,
                borderColor: `${isError ? 'error.main' : 'grey.300'}`,
                borderRadius: tileGrid ? '4px !important' : undefined,
                borderTopRightRadius: squareBordersOnLastButton ? '0px !important' : '',
                borderBottomRightRadius: squareBordersOnLastButton ? '0px !important' : '',
                backgroundColor: tileGrid ? '#fff !important' : undefined,
                borderTopLeftRadius: iconStart ? '0px' : undefined,
                borderBottomLeftRadius: iconStart ? '0px' : undefined,
                borderLeftColor: (theme: Theme) => getLeftBorderColor(theme, tileGrid, isError),
                '&.Mui-selected': {
                  // Styling borders gets really complicated because currently we may be doing too much with our toggle buttons
                  // We support tileGrid, regular inline, exclusive, multiselect, required, optional, etc
                  // In the future we should probably break this up into smaller simpler components
                  // At minimum, the TileGrid should be it's own component
                  borderColor: (theme: Theme) => getSelectedBorderColor(theme, isError),
                  backgroundColor: `${primary[25]} !important`,
                  '&+.MuiToggleButtonGroup-grouped.Mui-selected': {
                    borderLeft: `1px solid ${primary['DEFAULT']} !important`,
                    marginLeft: `-1px !important`,
                  },
                },
                height: !dynamicHeight ? '40px' : 'inherit',
                paddingBottom: tileGrid ? '24px' : undefined,
              }}
              className={twMerge(`flex-col px-2 text-sm font-bold tracking-widest ${itemClass ?? ''}`)}
              disableRipple={true}
              value={item.value}
            >
              {item.icon ? ToggleButtonIcon(item) : null}
              {item.label}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </div>
  )
}

export default UiToggleButtonGroup

function getSelectedBorderColor(theme: Theme, isError: boolean) {
  return `${isError ? theme.palette.error.main : theme.palette.primary.main} !important`
}

function getLeftBorderColor(theme: Theme, tileGrid: boolean | undefined, isError: boolean) {
  if (isError) {
    return `${theme.palette.error.main} !important`
  }

  return tileGrid ? `${theme.palette.grey['300']} !important` : undefined
}
