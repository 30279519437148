import { forwardRef } from 'react'
import NumberFormat, { InputAttributes } from 'react-number-format'

// This component is used in the InputField component to format its value as a number
// Based on https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries

export interface CustomNumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  value?: string
}

export const CustomNumberFormat = forwardRef<NumberFormat<InputAttributes>, CustomNumberFormatProps>((props, ref) => {
  const { onChange, ...rest } = props

  return (
    <NumberFormat
      {...rest}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={true}
      isNumericString={true}
      data-testid="custom-number-format"
    />
  )
})

export default CustomNumberFormat
