import { InputLabel } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { twMerge } from 'tailwind-merge'

import { EmptyField } from './empty-field'
import { ExternalSchemaField, SelectSchema, TextInputSchema, ToggleButtonSchema } from './models'
import { RequiredDot } from './required-dot'

export interface SchemaFormLabelProps {
  field: SelectSchema | TextInputSchema | ToggleButtonSchema | ExternalSchemaField
  isFieldComplete: boolean
  showRequiredDot?: boolean
  className?: string
}

/**
 * @deprecated As this uses MUI
 */
export const SchemaFormLabel = ({
  field,
  isFieldComplete,
  showRequiredDot = true,
  className = '',
}: SchemaFormLabelProps) => {
  const { t } = useTranslation()

  return (
    <InputLabel
      className={twMerge('relative pb-2 text-shades-600', className)}
      sx={{ fontSize: '14px' }}
      htmlFor={field.key}
    >
      {field.label ? t(field.label) : <EmptyField />}
      {showRequiredDot && field.validators['required'] && <RequiredDot success={isFieldComplete} />}
    </InputLabel>
  )
}
