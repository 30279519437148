export interface FormBreakProps {
  cols: number
}

/**
 * @deprecated Only works inside schema form
 */
export function FormBreak({ cols }: FormBreakProps) {
  return <div className={`col-span-${cols}`}></div>
}

export default FormBreak
