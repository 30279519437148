import { twMerge } from 'tailwind-merge'

export interface RequireDotProps {
  success?: boolean
  className?: string
}

/**
 * @deprecated Only works inside schema form
 */
export const RequiredDot = ({ success = false, className = '' }: RequireDotProps) => {
  return (
    <div
      className={twMerge(
        `absolute right-0 top-0 mt-2 h-[5px] w-[5px] rounded-[5px]`,
        success ? 'bg-primary' : 'bg-shades-600',
        className
      )}
    ></div>
  )
}
