import { NoOp } from '@unreserved-frontend-v2/utils/noop'
import { FormEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export type CheckboxProps = {
  label: string
  value?: boolean
  onChange?: (event: FormEvent, newValue: boolean) => void
  className?: string
  checkboxContainerClassName?: string
  checkboxClassName?: string
}

export const Checkbox = ({
  label,
  value = false,
  onChange = NoOp,
  className,
  checkboxContainerClassName,
  checkboxClassName,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(value)

  const handleChange = (e: FormEvent) => {
    onChange(e, !isChecked)
    setIsChecked(!isChecked)
  }

  // Need to react to external value changes as well (not just the default above when creating the state).
  useEffect(() => {
    setIsChecked(value)
  }, [value])

  return (
    <label
      className={twMerge('flex cursor-pointer flex-wrap items-center text-base text-shades-600', className)}
      data-testid={isChecked ? 'active-option' : ''}
      onChange={(e) => handleChange(e)}
    >
      <input data-testid="checkbox" type="checkbox" className="hidden" name={label} id={label} />
      <div
        className={twMerge(
          'flex-0 mr-3.5 rounded border',
          isChecked ? 'border-primary' : 'border-shades-200',
          checkboxContainerClassName
        )}
      >
        <div
          className={twMerge('m-[2px] h-[18px] w-[18px] rounded', isChecked ? 'bg-primary-500' : '', checkboxClassName)}
        />
      </div>
      <span className="flex-1 pt-px">{label}</span>
    </label>
  )
}

export default Checkbox
