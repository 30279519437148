import { faLoader } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from 'tailwind-merge'

export interface SpinnerProps {
  /** size is only to control the size of the deprecated default laoder */
  size?: number
  className?: string
  /** default is the deprecated version of the spinner, primary is the version to use */
  variant?: 'default' | 'primary'
  /** color to be used for spinner if variant is primary. new colors must be added to spinner.css */
  color?: 'darkGrey' | 'white'
}

/**
 * Component used when something is loading
 *
 * variant default is deprecated, use primary instead
 *
 * font-size controls the size of the spinner, each circle is equal to the font-size, default is 5px
 */
export function Spinner({ size = 22, className = '', variant = 'default', color = 'darkGrey' }: SpinnerProps) {
  return variant === 'default' ? (
    <FontAwesomeIcon icon={faLoader} transform={{ size }} className={twMerge('animate-spin', className)} />
  ) : (
    <div
      className={twMerge(
        `loader-${color} relative h-[1em] w-[1em] rounded-full indent-[-9999em] text-[5px] [transform:translateZ(0)]`,
        className
      )}
    ></div>
  )
}

export default Spinner
