import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format, isValid } from 'date-fns'
import useTranslation from 'next-translate/useTranslation'
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { getSimpleDate } from '@unreserved-frontend-v2/utils/date-formatters'

import { SchemaFormLabel } from './label'
import { TextInputSchema } from './models'
import { getPlaceholderKey } from './utils'
import InputField from '../input-field/input-field'

export interface SchemaFormDatePickerProps {
  field: TextInputSchema
  setValue: UseFormSetValue<FieldValues>
  control: Control
  isFieldComplete: boolean
}

/**
 * @deprecated As this uses MUI
 */
export const SchemaFormDatePicker = ({ field, setValue, control, isFieldComplete }: SchemaFormDatePickerProps) => {
  const { t } = useTranslation()
  return (
    <div className={twMerge(`mb-5 ${field.wrapperClasses}`)} key={field.key}>
      <SchemaFormLabel field={field} isFieldComplete={isFieldComplete} />
      <Controller
        control={control}
        name={field.key}
        // TODO V2-1288: remove client side validation properly when there's time to refactor
        // rules={field.validators}
        defaultValue={null}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              onChange={(date, displayValue) => {
                // this is to set the date when typing
                if (displayValue) {
                  onChange(displayValue)
                }
                // this is to set the date when picking from the calendar
                if (date && isValid(date)) {
                  onChange(format(date, 'yyyy-MM-dd'))
                  setValue(field.key, format(date, 'yyyy-MM-dd'))
                }
              }}
              minDate={getSimpleDate()}
              value={value}
              inputFormat="yyyy-MM-dd"
              mask="____-__-__"
              inputRef={ref}
              renderInput={(params) => (
                <InputField
                  id={name}
                  value={value}
                  name={name}
                  onBlur={onBlur}
                  {...params}
                  placeholder={t(getPlaceholderKey(field.validators['required']))}
                  color={invalid ? 'error' : 'primary'}
                  error={invalid}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </div>
  )
}
