import { MenuItem } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { Control, Controller } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { SchemaFormLabel } from './label'
import { SelectSchema } from './models'
import { getPlaceholderKey } from './utils'
import InputField from '../input-field/input-field'

export interface SchemaFormSelectProps {
  field: SelectSchema
  control: Control
  isFieldComplete: boolean
}

/**
 * @deprecated As this uses MUI
 */
export const SchemaFormSelect = ({ field, control, isFieldComplete }: SchemaFormSelectProps) => {
  const { t } = useTranslation()

  return (
    <div className={twMerge(`mb-5 ${field.wrapperClasses}`)} key={field.key}>
      <SchemaFormLabel field={field} isFieldComplete={isFieldComplete} />
      <Controller
        control={control}
        name={field.key}
        // TODO V2-1288: remove client side validation properly when there's time to refactor
        // rules={field.validators}
        defaultValue={field.defaultValue}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
          <InputField
            id={name}
            select={true}
            name={name}
            error={invalid}
            color={invalid ? 'error' : 'primary'}
            value={value === null ? '' : value}
            onBlur={onBlur}
            onChange={onChange}
            inputRef={ref}
            placeholder={t(getPlaceholderKey(field.validators['required']))}
          >
            {field.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </InputField>
        )}
      />
    </div>
  )
}
