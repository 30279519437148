import { useMemo } from 'react'
import tailWindConfig from 'tailwind-workspace-preset'

const {
  theme: {
    colors: { primary, shades, orange, white, red },
  },
} = tailWindConfig

export interface TailwindConfig {
  primary: Record<string | number, string>
  shades: Record<string | number, string>
  orange: Record<string | number, string>
  white: Record<string | number, string>
  red: Record<string | number, string>
}

export function useTailwindConfig() {
  return useMemo((): TailwindConfig => ({ primary, shades, orange, white, red }), [])
}
