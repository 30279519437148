import useTranslation from 'next-translate/useTranslation'
import { Control, Controller } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { SchemaFormLabel } from './label'
import { TextInputSchema } from './models'
import { getPlaceholderKey } from './utils'
import InputField from '../input-field/input-field'

export interface SchemaFormTextFieldProps {
  field: TextInputSchema
  control: Control
  isFieldComplete: boolean
  dataTestId?: string
}

/**
 * @deprecated Only works inside schema form
 */
export const SchemaFormTextField = ({ field, control, isFieldComplete, dataTestId = '' }: SchemaFormTextFieldProps) => {
  const { t } = useTranslation()
  return (
    <div className={twMerge(`mb-5 ${field.wrapperClasses}`)} key={field.key}>
      <SchemaFormLabel field={field} isFieldComplete={isFieldComplete} />
      <Controller
        control={control}
        name={field.key}
        // TODO V2-1288: remove client side validation properly when there's time to refactor
        // rules={field.validators}
        defaultValue={''}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
          return (
            <InputField
              id={name}
              multiline={field.type === 'textarea'}
              rows={field.type === 'textarea' ? 3 : 0}
              name={name}
              error={invalid}
              color={invalid ? 'error' : 'primary'}
              onBlur={onBlur}
              inputRef={ref}
              placeholder={t(getPlaceholderKey(field.validators['required']))}
              endIcon={field.endIcon}
              startIcon={field.startIcon}
              type={field.type}
              inputProps={{
                'data-testid': dataTestId,
              }}
              //
              // Solution for number/tel field is based on this:
              // https://github.com/mui/material-ui/issues/8380#issuecomment-441436757
              //
              value={value === null ? '' : value.toString()}
              onChange={(event) => {
                const newVal = event.target.value
                if (field.type === 'tel' || field.type === 'number') {
                  // First try to convert to a number. Do NOT try and convert '' because +'' converts to 0 and we
                  // specifically don't want to display 0 for a blank string.
                  const number = event.target.value === '' ? '' : +event.target.value

                  // If we get NaN it means the new value is not valid so strip the last character from the end,
                  // (which effectively means the string in the UI doesn't change).
                  onChange(Number.isNaN(number) ? newVal.substring(0, newVal.length - 1) : newVal)
                } else {
                  onChange(event.target.value)
                }
              }}
            />
          )
        }}
      />
    </div>
  )
}
