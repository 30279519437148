import { IconProp, Transform } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextField, TextFieldProps } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { ValueHash } from '@unreserved-frontend-v2/utils/types'

import CustomNumberFormat from '../custom-number-format/custom-number-format'

export interface InputFieldProps {
  startIcon?: IconProp
  startIconClassName?: string
  onStartIconClick?: (event: React.BaseSyntheticEvent) => void
  endIcon?: IconProp
  endIconTransform?: Transform
  onEndIconClick?: (event: React.BaseSyntheticEvent) => void
  endButtonIcon?: IconProp
  endButtonIconClick?: (event: React.BaseSyntheticEvent) => void
  startText?: string
  endText?: string
  inputFormat?: InputFormats
  removeBorderStyles?: boolean
  sxValues?: ValueHash<string | ValueHash<string>>
  dataTestId?: string
}

export type InputFormats = 'currency'

/**
 * @deprecated As this uses MUI
 */
export function InputField(props: InputFieldProps & TextFieldProps) {
  const {
    startIcon,
    startIconClassName,
    onStartIconClick,
    endIcon,
    onEndIconClick,
    className: classNameProp,
    endButtonIcon,
    endButtonIconClick,
    startText,
    endText,
    inputFormat,
    removeBorderStyles = false,
    sxValues = {},
    endIconTransform,
    dataTestId = '',
    ...textFieldProps
  } = props
  const fullProps = {
    InputProps: {
      startAdornment:
        startIcon || startText ? (
          <InputAdornment
            className={twMerge('text-shades-600', startIconClassName)}
            position="start"
            onClick={onStartIconClick}
          >
            {startIcon ? <FontAwesomeIcon icon={startIcon} className="cursor-pointer" /> : null}
            {startText ? <span className="font-medium text-shades-800">{startText}</span> : null}
          </InputAdornment>
        ) : undefined,
      endAdornment:
        endIcon || endText ? (
          <InputAdornment position="end" onClick={onEndIconClick}>
            {endIcon ? (
              <FontAwesomeIcon icon={endIcon} className="cursor-pointer" transform={endIconTransform || {}} />
            ) : null}
            {endText ? <span className="font-medium text-shades-800">{endText}</span> : null}
          </InputAdornment>
        ) : undefined,
      inputComponent: inputFormat === 'currency' ? (CustomNumberFormat as never) : undefined,
    },
    ...textFieldProps,
  }

  /*                      | top  | right | bottom | left                           */
  const inputBasePadding = `10px   14px    10px     ${startIcon ? '14px' : '14px'}`
  const adornedStartPaddingLeft = startIcon ? '14px' : '8px'
  const positionStartMarginRight = startIcon ? '0px' : '8px'

  return (
    <div className="relative">
      <TextField
        sx={{
          // Base
          '.MuiInputBase-root': {
            borderRadius: removeBorderStyles ? '0px' : '5px',
            borderColor: '#DADBDD',
            width: '100%',
          },
          '.Mui-disabled': {
            backgroundColor: 'grey.50',
          },
          '.MuiInputBase-root.MuiInputBase-adornedStart': {
            paddingLeft: adornedStartPaddingLeft,
          },

          ...(endIcon
            ? {
                '.MuiSelect-icon': { display: 'none !important' },
                '.MuiInputAdornment-positionEnd': {
                  position: 'absolute',
                  right: '15px',
                  marginTop: '-2px',
                  color: 'grey.600',
                },
                '.MuiOutlinedInput-root': {
                  paddingRight: 0,
                },
              }
            : {}),
          '.MuiInputBase-input': {
            p: inputBasePadding,
            fontSize: '14px',
          },
          '.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: 'primary.DEFAULT',
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
          },
          // Start adornment
          '.MuiInputAdornment-root.MuiInputAdornment-positionStart': {
            marginRight: positionStartMarginRight,
          },
          // textarea
          '.MuiInputBase-multiline': {
            padding: '0px !important',
            lineHeight: '26px',
          },
          // error
          '.MuiInputBase-colorError, .Mui-error': {
            backgroundColor: 'error.50',
          },
          '.MuiInputBase-colorError input, .Mui-error input': {
            color: 'error.600',
          },
          '.MuiInputBase-colorError fieldset': {
            borderStyle: 'solid',
            borderColor: 'error.800',
          },
          // success
          '.MuiInputBase-colorSuccess': {
            backgroundColor: 'primary.50',
          },
          '.MuiInputBase-colorSuccess input': {
            color: 'primary.600',
          },
          '.MuiInputBase-colorSuccess fieldset': {
            borderStyle: 'solid',
            borderColor: 'primary.800',
          },
          '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: removeBorderStyles ? 0 : '1px',
          },
          ...sxValues,
        }}
        className={`w-full ${classNameProp} `}
        data-testid={dataTestId}
        {...fullProps}
      />
      {!endButtonIcon ? null : (
        <div
          onClick={endButtonIconClick}
          className="absolute right-0 top-0 flex h-[40px] w-[40px] items-center rounded-tr rounded-br border border-shades-200 bg-white"
        >
          <FontAwesomeIcon className="mx-auto" icon={endButtonIcon} />
        </div>
      )}
    </div>
  )
}

export default InputField
