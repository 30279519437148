import differenceInHours from 'date-fns/differenceInHours'
import format from 'date-fns/format'
import subDays from 'date-fns/subDays'

/**
 * A formatter that returns an RFC-3339 compliant Full Date (yyyy-MM-dd) by default
 *
 * @param daysPrior A negative offset to return the date that is XXX days from today
 * @param targetFormat A string format to be passed to `date-fns/format`
 */
export const getFormattedDateFromToday = (daysPrior: number, targetFormat = 'yyyy-MM-dd') => {
  return format(subDays(new Date().setHours(0, 0, 0, 0), daysPrior), targetFormat)
}

/**
 * Takes a date string in the formatt yyyy-mm-dd and converts it to a Date object
 *
 * By default, javascript doesn't handle the yyyy-mm-dd format without timezones accurately, so we need
 * to replace the '-' with '/' in order for javascript to accurately interpret the date
 *
 * @param str date string
 * @returns Date object
 */
export const massageDate = (str: string) => {
  return new Date(str.replace(/-/g, '/'))
}

/**
 * Returns an ISO string by removing the local timezone to allow consistent rendering of dates across clients/server.
 * @param str optional string or Date
 * @returns Date object with time set to UTC
 */
const getISODateString = (str?: string | Date) => {
  let date = str ? new Date(str) : new Date()
  if (isNaN(date as unknown as number)) {
    date = new Date()
  }

  return date.toISOString()
}

/**
 * Returns a Date representing the date part of an API DateTime after removing the local timezone).
 * @param str optional string or Date
 * @returns Date object with time set to UTC
 */
export const getSimpleDate = (str?: string | Date) => {
  const utcDate = getISODateString(str).split('T')[0]
  return new Date(utcDate)
}

/**
 * Returns the time part string of an API DateTime after removing the local timezone).
 * @param str optional string or Date
 * @returns Date object with time set to UTC
 */
export const getSimpleTime = (str?: string | Date) => {
  // Example: 2022-01-01T17:34:56.000Z ==> 17:34:561
  return getISODateString(str).split('T')[1].split('Z')[0].split('.')[0].substring(0, 5)
}
