//
// Head to https://regex101.com/ to test out the regex expressions.
//
export const datePatternValidator = {
  value: /^[2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/i,
  message: 'This is not a real date!',
}

export const emailPatternValidator = {
  value: /\S+@\S+\.\S+/,
  message: 'This is not a real email!',
}

export const urlPatternValidator = {
  value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  message: 'This is not a real URL!',
}

// TODO: Do we need to translate this?
export const requiredValidator = { required: { value: true, message: 'This field is required!' } }
