import useTranslation from 'next-translate/useTranslation'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, ValidationMode } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { SchemaFormAutocomplete } from './autocomplete'
import { SchemaCheckbox } from './checkbox'
import { SchemaFormDatePicker } from './date-picker'
import FormBreak from './form-break'
import { CheckboxSchema, SchemaFormField, SchemaFormSubSection, SelectSchema } from './models'
import NoField from './no-field'
import { SchemaFormSelect } from './select'
import { SchemaFormTextField } from './text-field'
import { SchemaFormToggle } from './toggle'
import { SchemaFormValues } from './types'
import Button from '../button/button'
import { ErrorContainer, FieldError } from '../error-container/error-container'
import { FlexCol } from '../flex/flex-col'
import { Spinner } from '../spinner'

export const DEFAULT_NUM_COLUMNS = 12

/**
 * This is a "light" version of the SchemaForm that is used to show/edit only a single section. It doesn't have any
 * border and will get its width from its parent container. It's main purpose is to be used in pages/modal that
 * require only a small bit of info (e.g. - register user, book a showing, etc.).
 */
export interface SchemaFormLiteProps {
  section: SchemaFormSubSection
  formId: string
  showTitle?: boolean
  showActionButton?: boolean
  showLoaderOnSubmit?: boolean
  errors?: FieldError[]
  errorClassName?: string
  className?: string
  values?: SchemaFormValues
  fieldToFocus?: string

  /** If true, schema form will render error messages above the form so you don't have to handle them */
  showErrors?: boolean

  /** How to do the form validation (e.g. - onChange, onSubmit, etc.) */
  formMode?: keyof ValidationMode

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit(data: any, event?: React.BaseSyntheticEvent): void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?(errors: any, event?: React.BaseSyntheticEvent): void

  /** if true, window will be scrolled to top when the error container has errors in it
   * The primary usecase here is for mobile where all our error messages appear at the top away from fields
   */
  scrollToTopOnError?: boolean
}

/**
 * @deprecated Old form system
 */
export function SchemaFormLite({
  section,
  formId,
  showTitle = true,
  showActionButton = true,
  showLoaderOnSubmit = true,
  errors,
  values,
  formMode = 'onChange',
  onError,
  onSubmit,
  className = '',
  fieldToFocus,
  showErrors = true,
  errorClassName,
  scrollToTopOnError,
}: SchemaFormLiteProps) {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const { handleSubmit, control, getFieldState, setValue, setError, getValues, trigger, clearErrors } = useForm({
    mode: formMode,
  })
  const { t } = useTranslation()

  /**
   * Helper that checks if the field is complete or not. Pass in requiredProperties to check a set of
   * properties that are required when the field is an object (e.g. - address).
   * @param field The field to check.
   * @param requiredFields Optional array of field names to check when field is an object
   * @returns true if the field is complete. false otherwise.
   */
  function isFieldComplete(field: SchemaFormField, requiredProperties?: string[]) {
    const state = getFieldState(field.key)
    const fieldValue = getValues(field.key)

    if (fieldValue == null || state.error) {
      return false
    }

    if (requiredProperties) {
      if (typeof fieldValue !== 'object') {
        throw new Error('requiredFields are only valid on an object field.')
      }

      let missingRequired = false
      requiredProperties.forEach((field) => {
        if (fieldValue[field] === '') {
          missingRequired = true
        }
      })

      return !missingRequired
    }

    return fieldValue !== ''
  }

  //
  // Values
  //
  useEffect(() => {
    if (values) {
      Object.keys(values).forEach((formControlKey) => {
        setValue(formControlKey, values[formControlKey], { shouldDirty: true, shouldTouch: true })
      })
    }
  }, [values, setValue, section])

  //
  // Errors.
  //
  useEffect(() => {
    if (errors) {
      setSubmitted(false)

      errors.forEach((err) => {
        setError(err.invalidField, { message: err.errorMessage }, { shouldFocus: !scrollToTopOnError })
      })
    }
  }, [setError, errors, scrollToTopOnError])

  useEffect(() => {
    // Normally we wouldn't access the DOM directly but because of the way we build up our forms
    // using a schema and the fact that it isn't possible to pass/use refs to all the different types of
    // fields, we are getting the elements here directly from the DOM to focus them.
    if (fieldToFocus) {
      document.getElementById(fieldToFocus)?.focus()
    }
  }, [fieldToFocus, scrollToTopOnError])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (errors: any, event?: React.BaseSyntheticEvent) => {
    setSubmitted(false)
    if (onError) {
      onError(errors, event)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSubmit = (data: any) => {
    onSubmit(data)
  }

  const handleCTAClick = useCallback(() => {
    // Want this to update the state as soon as possible to display the CTA loader so call it here
    // and not in handleOnSubmit.
    setSubmitted(true)

    // Because we set our field errors manually using setError, we need to clear the errors manually or else we
    // won't be able to re-submit the form (https://github.com/react-hook-form/react-hook-form/issues/70).
    clearErrors()
  }, [clearErrors])

  // Need to know number of columns here so that we can pass it to the FormBreak below so that it takes up an
  // entire row of the grid. Default to 12.
  const cols = section.numColumns ?? DEFAULT_NUM_COLUMNS

  return (
    <FlexCol id="schema-form-lite-container" className={twMerge(`w-full items-center`, className)}>
      {showTitle && section.title ? (
        <div className={twMerge('pt-8 pb-5 text-lg font-bold text-shades-600', section.titleClassName)}>
          {t(section.title)}
        </div>
      ) : null}

      <ErrorContainer
        errors={errors}
        showErrors={showErrors}
        className={errorClassName}
        scrollToTopOnError={scrollToTopOnError}
      />

      <form id={formId} onSubmit={handleSubmit(handleOnSubmit, handleError)} className="w-full">
        <fieldset
          className={twMerge(
            `mx-auto grid max-w-7xl grid-cols-${cols} gap-x-[14px] md:gap-x-6`,
            section.className,
            `${errors?.length && showErrors ? 'pt-[26px]' : null}`
          )}
          key={section.key}
        >
          {section.fields.map((field: SchemaFormField) => {
            switch (field.type) {
              case 'text':
              case 'tel':
              case 'number':
              case 'textarea':
              case 'password':
                return (
                  <React.Fragment key={`text-${field.key}`}>
                    <SchemaFormTextField
                      key={field.key}
                      field={field}
                      control={control}
                      dataTestId={field.dataTestId}
                      isFieldComplete={isFieldComplete(field)}
                    />
                    {field.breakRowAfter ? <FormBreak cols={cols} /> : null}
                  </React.Fragment>
                )
              case 'date':
                return (
                  <SchemaFormDatePicker
                    key={field.key}
                    field={field}
                    setValue={setValue}
                    control={control}
                    isFieldComplete={isFieldComplete(field)}
                  />
                )
              case 'select':
                return (
                  <SchemaFormSelect
                    key={field.key}
                    field={field}
                    control={control}
                    isFieldComplete={isFieldComplete(field)}
                  />
                )
              case 'toggle':
                return (
                  <React.Fragment key={`toggle-${field.key}`}>
                    <SchemaFormToggle
                      key={field.key}
                      field={field}
                      control={control}
                      setValue={setValue}
                      triggerValidation={trigger}
                      isFieldComplete={isFieldComplete(field)}
                    />
                    {field.breakRowAfter ? <FormBreak cols={cols} /> : null}
                  </React.Fragment>
                )
              case 'autocomplete':
              case 'autocompleteMulti':
                return (
                  <SchemaFormAutocomplete
                    key={field.key}
                    field={field as SelectSchema}
                    control={control}
                    multiple={field.type === 'autocompleteMulti'}
                    isFieldComplete={isFieldComplete(field)}
                  />
                )
              case 'checkbox':
                return (
                  <SchemaCheckbox
                    key={field.key}
                    field={field as CheckboxSchema}
                    setValue={setValue}
                    control={control}
                  />
                )
              default:
                return <NoField />
            }
          })}
        </fieldset>
      </form>

      {showActionButton ? (
        <Button className="w-full" form={formId} onClick={handleCTAClick}>
          {submitted && showLoaderOnSubmit ? <Spinner /> : t(section.actionLabelKey || '')}
        </Button>
      ) : null}
    </FlexCol>
  )
}

export default SchemaFormLite
