import useTranslation from 'next-translate/useTranslation'
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { CheckboxSchema } from './models'
import { Checkbox } from '../checkbox/checkbox'

export interface SchemaCheckboxProps {
  field: CheckboxSchema
  control: Control
  setValue: UseFormSetValue<FieldValues>
}

/**
 * @deprecated Only works inside schema form
 */
export const SchemaCheckbox = ({ field, control, setValue }: SchemaCheckboxProps) => {
  const { t } = useTranslation()
  return (
    <div className={twMerge(`mb-5 ${field.wrapperClasses}`)} key={field.key}>
      <Controller
        control={control}
        name={field.key}
        // TODO V2-1288: remove client side validation properly when there's time to refactor
        // rules={field.validators}
        defaultValue={field.defaultValue}
        render={() => {
          return (
            <Checkbox
              value={field.defaultValue}
              label={t(field.label)}
              onChange={(_, value) => setValue(field.key, value)}
            />
          )
        }}
      />
    </div>
  )
}
