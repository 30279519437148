import { Autocomplete } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { ValueHash } from '@unreserved-frontend-v2/utils/types'

import { SchemaFormLabel } from './label'
import { SelectSchema } from './models'
import { getPlaceholderKey } from './utils'
import InputField from '../input-field/input-field'

export interface SchemaFormAutocompleteProps {
  field: SelectSchema
  control: Control
  multiple?: boolean
  isFieldComplete: boolean
}

/**
 * @deprecated As this uses MUI
 */
export const SchemaFormAutocomplete = ({ field, control, multiple, isFieldComplete }: SchemaFormAutocompleteProps) => {
  const { t } = useTranslation()
  const [optionsHash, setOptionsHash] = useState<ValueHash<string>>({})

  useEffect(() => {
    setOptionsHash(
      field.options.reduce((memo, item) => {
        memo[item.value] = t(item.label)
        return memo
      }, {} as ValueHash<string>)
    )
  }, [field.options, setOptionsHash, t])

  return (
    <div className={twMerge(`mb-5 ${field.wrapperClasses}`)} key={field.key}>
      <SchemaFormLabel field={field} isFieldComplete={isFieldComplete} />
      <Controller
        control={control}
        name={field.key}
        // TODO V2-1288: remove client side validation properly when there's time to refactor
        // rules={field.validators}
        render={({ field: { onBlur, value, name, ref, onChange }, fieldState: { error } }) => (
          <Autocomplete
            disablePortal={true}
            options={field.options.map((option) => option.value)}
            id={field.key}
            multiple={multiple}
            getOptionLabel={(option) => optionsHash[option] || ''}
            isOptionEqualToValue={(option, value) => {
              return value === '' ? true : option === value
            }}
            value={multiple ? value || [] : value || ''}
            onChange={(_, options) => {
              onChange(options)
            }}
            renderInput={(params) => (
              <InputField
                {...params}
                id={name}
                name={name}
                error={Boolean(error)}
                color={error ? 'error' : 'primary'}
                onBlur={onBlur}
                inputRef={ref}
                placeholder={t(getPlaceholderKey(field.validators['required']))}
              />
            )}
          />
        )}
      />
    </div>
  )
}
