import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface CenteredProps {
  fullscreen?: boolean
  className?: string
  children: ReactNode
  id?: string
}

/**
 * Centers the passed-in children on the screen.
 */
export function Centered({ id = '', children, fullscreen = true, className = '' }: CenteredProps) {
  return (
    <div id={id} className={twMerge(fullscreen ? `height-full` : '', className)}>
      {children}
    </div>
  )
}

export default Centered
