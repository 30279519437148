import Link from 'next/link'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { UrlObject } from 'url'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Button variant, being `primary` green, `secondary` gray, and `ghost` gray and outlined */
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'secondaryInverse'
    | 'ghost'
    | 'ghostLight'
    | 'ghostLightStatic'
    | 'unstyled'
    | 'trueUnstyled'
    | 'selected'
    | 'overlay'
    | 'danger'
  /** The width of the button, being `full` adapted to its parent, and `compact` adapted to its content */
  width?: 'full' | 'compact'
  /** The size of the button. */
  size?: 'small' | 'tall'
  /** If provided, button will be a link instead */
  href?: string | UrlObject
  /** Target for href to open in */
  target?: string
  /** Target for href to open in */
  query?: string
  /** Whether or not this button is considered active/selected */
  active?: boolean
  /** Optional test indicator property. Needs to be explicit because Link cannot receive bulk of button props */
  'data-testid'?: string
  /** when using href, optionally provide a rel */
  rel?: string

  targetPaddingClassName?: string
}

/**
 * A button component that can be used to trigger an event
 * @param props { ButtonProps } - { `children`, `onClick`, `width`, `size`, `variant`, `disabled` }
 * @constructor
 */

export function Button({
  children,
  variant = 'primary',
  width = 'compact',
  size = 'tall',
  disabled = false,
  className: classNameProp,
  targetPaddingClassName,
  href,
  active,
  target,
  'data-testid': dataTestId,
  rel,
  ...htmlButtonProps
}: ButtonProps) {
  const baseClasses =
    'block rounded-[5px] px-4 text-sm leading-[14px] font-bold uppercase tracking-widest text-white transition duration-200 ease-in-out'

  const variantClasses = {
    primary: 'bg-primary active:bg-primary-800 hover:bg-primary-600 text-white',
    secondary: 'bg-shades-600 active:bg-shades-600 hover:bg-shades-400 text-white',
    tertiary: 'bg-orange-400 active:bg-orange-600 hover:bg-orange-600 text-white',
    secondaryInverse:
      'flex items-center rounded-[46px] hover:bg-shades-400 bg-none text-shades-200 px-0 justify-center',
    ghost: 'bg-white active:bg-white hover:bg-shades-200 border border-shades-600 text-shades-600',
    ghostLight: 'bg-white active:bg-white hover:bg-shades-50 border border-shades-200 text-shades-600',
    // no hover or active effects
    ghostLightStatic: 'bg-white border border-shades-200 text-shades-600',
    unstyled: '',
    trueUnstyled: '',
    selected: 'text-primary border border-primary bg-primary-25',
    overlay: 'border border-white rounded-[50px] bg-transparent text-white',
    danger: 'bg-red-600 active:bg-red-800 hover:bg-red-800 text-white',
  }

  const widthClasses = {
    full: 'w-full',
    compact: 'w-auto',
  }

  const sizeClasses = {
    small: 'py-2 h-8',
    tall: 'py-3 h-10',
  }

  const disabledClasses = {
    primary: 'bg-shades-200 hover:bg-shades-200 cursor-not-allowed',
    secondary: 'bg-shades-200 hover:bg-shades-200 cursor-not-allowed',
    tertiary: 'bg-shades-200 hover:bg-shades-200 cursor-not-allowed',
    secondaryInverse: '',
    ghost: 'bg-white hover:bg-white text-shades-200 border-shades-200 cursor-not-allowed',
    ghostLight: 'bg-white hover:bg-white text-shades-200 border-shades-200 cursor-not-allowed',
    ghostLightStatic: 'cursor-not-allowed',
    unstyled: '',
    trueUnstyled: '',
    selected: 'cursor-not-allowed',
    overlay: '',
    danger: 'cursor-not-allowed',
  }

  const activeClasses = {
    primary: '',
    secondary: '',
    tertiary: '',
    secondaryInverse: 'bg-shades-600 active:bg-shades-600 hover:bg-shades-400 text-white',
    ghost: '',
    ghostLight: 'bg-shades-600 active:bg-shades-600 hover:bg-shades-400 text-white',
    ghostLightStatic: '',
    unstyled: '',
    trueUnstyled: '',
    selected: '',
    overlay: '',
    danger: '',
  }

  const classNames =
    variant === 'trueUnstyled'
      ? classNameProp
      : twMerge(
          baseClasses,
          variantClasses[variant || 'primary'],
          active ? activeClasses[variant || 'primary'] : '',
          widthClasses[width || 'compact'],
          sizeClasses[size || 'tall'],
          disabled ? disabledClasses[variant || 'primary'] : '',
          classNameProp ?? ''
        )

  if (href) {
    return (
      <Link rel={rel} data-testid={dataTestId} href={href} className={`text-center ${classNames}`} target={target}>
        {children}
      </Link>
    )
  }

  // For SEO, we need to have the button's target area be larger than the button itself. To create this effect,
  // the button acts as a wrapper for the child component (which is the one that has the border) and the button
  // effectively just adds padding around the child component.
  return targetPaddingClassName ? (
    <button data-testid={dataTestId} {...htmlButtonProps} className={targetPaddingClassName}>
      <div className={classNames}>{children}</div>
    </button>
  ) : (
    <button {...htmlButtonProps} data-testid={dataTestId} className={classNames}>
      {children}
    </button>
  )
}

export default Button
