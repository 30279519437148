import {
  datePatternValidator,
  emailPatternValidator,
  requiredValidator,
  urlPatternValidator,
} from '@unreserved-frontend-v2/utils/validators'
import { ValueHash } from '@unreserved-frontend-v2/utils/types'
import {
  CheckboxSchema,
  ExternalSchemaField,
  SchemaFormField,
  SelectSchema,
  TextInputSchema,
  ToggleButtonSchema,
} from './models'
import { faDollarSign, faMap, faPercent } from '@fortawesome/pro-solid-svg-icons'
import { twMerge } from 'tailwind-merge'
import { ToggleButtonItem } from '../ui-toggle-button-group/ui-toggle-button-group'

/**
 * Creates a text input for use in the forms.
 */
export const text = (
  key: string,
  label: string,
  wrapperClasses: string,
  required?: boolean,
  dataTestId?: string
): TextInputSchema => {
  return {
    label,
    key,
    type: 'text',
    dataTestId,
    validators: { ...(required ? requiredValidator : {}) },
    wrapperClasses,
  }
}

/**
 * Creates a dollar amount text input for use in the forms.
 */
export const dollar = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    ...text(key, label, `col-span-${cols}`, required),
    subType: 'dollar',
    type: 'number',
    startIcon: faDollarSign,
  }
}

/**
 * Creates a number text input for use in the forms.
 */
export const number = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    ...text(key, label, typeof cols === 'number' ? `col-span-${cols}` : cols, required),
    type: 'number',
  }
}

/**
 * Creates a tel phone text input for use in the forms.
 */
export const phone = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    ...text(key, label, typeof cols === 'number' ? `col-span-${cols}` : cols, required),
    type: 'tel',
  }
}

/**
 * Creates a checkbox input for use in the forms.
 */
export const checkbox = (key: string, label: string, cols: number | string, defaultValue?: boolean): CheckboxSchema => {
  return {
    label,
    key,
    type: 'checkbox',
    validators: {},
    wrapperClasses: typeof cols === 'number' ? `col-span-${cols}` : cols,
    defaultValue,
  }
}

/**
 * Creates an external field definition input for use in the forms.
 * @param key The key for the React element. Must be unique across the entire SchemaForm (e.g. - address1, address2, etc.)
 * @param componentType Used in SchemaForm as the key into the list of external components to render (e.g. - address)
 * @param label The label to display for the field.
 * @param cols The number of columns the field take up on the form.
 * @param required True if the field is required. False otherwise.
 * @param externalProperties An hash of values that the external component needs but that they SchemaForm isn't aware of. For example, a mapId to use for the div containing the map.
 * @returns
 */
export const external = (
  key: string,
  componentType: string,
  label: string,
  cols: number | string,
  required: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalProperties?: ValueHash<any>
): ExternalSchemaField => {
  return {
    type: 'external',
    componentType,
    label,
    key,
    endIcon: faMap,
    wrapperClasses: typeof cols === 'string' ? `${cols}` : `col-span-${cols}`,
    validators: { ...(required ? requiredValidator : {}) },
    ...externalProperties,
  }
}

/**
 * Creates an email text input for use in the forms.
 */
export const email = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    ...text(key, label, typeof cols === 'number' ? `col-span-${cols}` : cols, required),
    validators: { ...(required ? requiredValidator : {}), pattern: emailPatternValidator },
  }
}

/**
 * Creates a URL text input for use in the forms.
 */
export const url = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    ...text(key, label, `col-span-${cols}`, required),
    validators: { ...(required ? requiredValidator : {}), pattern: urlPatternValidator },
  }
}

/**
 * Creates a select input for use in the forms.
 */
export const select = (
  key: string,
  label: string,
  cols: number | string,
  options: { value: string; label: string }[],
  required?: boolean
): SelectSchema => {
  return {
    ...text(key, label, typeof cols === 'number' ? `col-span-${cols}` : cols, required),
    type: 'select',
    options: options,
    defaultValue: options[0].value,
  }
}

/**
 * Creates an Autocomplete input for use in the forms.
 */
export const autocomplete = (
  key: string,
  label: string,
  cols: number | string,
  options: { value: string; label: string }[],
  required?: boolean
): SelectSchema => {
  return {
    ...select(key, label, cols, options, required),
    type: 'autocomplete',
  }
}

/**
 * Creates an Autocomplete Multi input for use in the forms.
 */
export const autocompleteMulti = (
  key: string,
  label: string,
  cols: number | string,
  options: { value: string; label: string }[],
  required?: boolean
): SelectSchema => {
  return {
    ...select(key, label, cols, options, required),
    type: 'autocompleteMulti',
  }
}

/**
 * Creates a percent input field for use in the forms.
 */
export const percent = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    subType: 'percent',
    endIcon: faPercent,
    ...number(key, label, cols, required),
  }
}

/**
 * Creates a date input field for use in the forms.
 */
export const date = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    label,
    key,
    type: 'date',
    validators: { ...(required ? requiredValidator : {}), pattern: datePatternValidator },
    wrapperClasses: typeof cols === 'string' ? `${cols}` : `col-span-${cols}`,
  }
}

/**
 * Creates a toggle field input for use in the forms.
 */
export const toggle = (
  key: string,
  options: ToggleButtonItem[],
  label = '',
  wrapperClasses = '',
  tileGrid = false,
  dynamicHeight = false,
  required = true,
  defaultValue?: string | string[] | null,
  canUnselectIfRequired = false,
  exclusive = true,
  resetButtonKey = '',
  horizontalToggle = false
): ToggleButtonSchema => {
  return {
    type: 'toggle',
    key,
    label: label,
    validators: { ...(required ? requiredValidator : {}) },
    wrapperClasses: twMerge('col-span-8', wrapperClasses),
    defaultValue: defaultValue === undefined ? options[0].value : defaultValue,
    options: options,
    tileGrid,
    dynamicHeight,
    canUnselectIfRequired,
    exclusive,
    resetButtonKey,
    horizontalToggle,
  }
}

/**
 * Creates a textArea input for use in the forms.
 */
export const textArea = (key: string, label: string, cols: number | string, required?: boolean): TextInputSchema => {
  return {
    label,
    key,
    type: 'textarea',
    validators: { ...(required ? requiredValidator : {}) },
    wrapperClasses: typeof cols === 'string' ? `${cols}` : `col-span-${cols}`,
  }
}

export const required = (field: SchemaFormField): SchemaFormField => {
  return {
    ...field,
    validators: { ...requiredValidator },
  }
}

/**
 * Get the required/optional placeholder value for a field.
 */
export function getPlaceholderKey(required: boolean) {
  return `ui:schema-field.${required ? 'required' : 'optional'}`
}
