import useTranslation from 'next-translate/useTranslation'
import { useMemo, useState } from 'react'
import { Control, Controller, FieldValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { SchemaFormLabel } from './label'
import { ToggleButtonSchema } from './models'
import { RequiredDot } from './required-dot'
import Button from '../button/button'
import { FlexRow } from '../flex/flex-row'
import Grid from '../grid/grid'
import UiToggleButtonGroup, { ToggleButtonItem } from '../ui-toggle-button-group/ui-toggle-button-group'

export interface SchemaFormToggleProps {
  field: ToggleButtonSchema
  control: Control
  isFieldComplete: boolean
  setValue: UseFormSetValue<FieldValues>
  triggerValidation?: UseFormTrigger<FieldValues>
}

/**
 * @deprecated Only works inside schema form
 */
export const SchemaFormToggle = ({
  field,
  control,
  isFieldComplete,
  setValue,
  triggerValidation,
}: SchemaFormToggleProps) => {
  const { t } = useTranslation()

  /** This state is used just for the resetButton whenever it's enabled */
  const [hasValue, setHasValue] = useState(
    field.resetButtonKey && fieldHasValue(control._formValues[field.key] || field.defaultValue)
  )

  // The "any" comes straight from the MUI component.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleToggleButtonGroupChange = (_: React.MouseEvent<HTMLElement>, newValue: any) => {
    setHasValue(Boolean(newValue?.length))
    // Don't allow changing the value if the field is required and we've configured the field to not allow
    // unselecting. This gives us the option to unselect a toggle button but then have the validation
    // kick in when the form is submitted.
    if (newValue === null && !field.canUnselectIfRequired && field.validators['required']?.value) {
      return
    }

    setValue(field.key, newValue)

    // NOTE:  Had to use trigger (https://react-hook-form.com/api/useform/trigger/) from reac-form-hook
    //        because for the toggle buttons, validating on change or blur just wasn't working.
    if (triggerValidation) {
      triggerValidation(field.key)
    }
  }

  const handleReset = () => {
    setValue(field.key, null, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    setHasValue(false)
  }

  const items = useMemo(() => {
    const newItems = field.options.map((option: ToggleButtonItem) => ({
      label: option.label ? t(option.label) : '',
      value: option.value,
      icon: option.icon,
      iconType: option.iconType,
      svgClassName: 'pt-3 pb-2',
    }))

    return newItems
  }, [field.options, t])

  const buttonGroup = (
    <Controller
      control={control}
      name={field.key}
      // TODO V2-1288: remove client side validation properly when there's time to refactor
      // rules={field.validators}
      defaultValue={field.defaultValue}
      render={({ field: { value }, fieldState: { invalid } }) => {
        return (
          <UiToggleButtonGroup
            exclusive={field.exclusive}
            value={value}
            dynamicHeight={field.dynamicHeight}
            fullWidth={!field.contain}
            isError={invalid}
            tileGrid={Boolean(field.tileGrid)}
            items={items}
            onChange={handleToggleButtonGroupChange}
            squareBordersOnLastButton={!!field.resetButtonKey && !field.tileGrid}
            containerClassName={twMerge('flex-grow', hasValue ? 'z-[1]' : 'z-[0]')}
          />
        )
      }}
    />
  )

  const resetButton = field.resetButtonKey ? (
    <Button
      onClick={handleReset}
      type="button"
      className={twMerge(field.tileGrid ? 'mt-4' : 'z-[1] -ml-[1px] rounded-l-[0px]', hasValue ? 'z-[0]' : 'z-[1]')}
      variant={hasValue ? 'ghostLightStatic' : 'selected'}
      width="full"
    >
      {t(field.resetButtonKey)}
    </Button>
  ) : null

  return field.horizontalToggle ? (
    //
    // Layout where the label/buttons are all on the one line. Note you shouldn't use a reset button with this layout.
    //
    <div className={twMerge(`my-5 ${field.wrapperClasses}`)} key={field.key}>
      <Grid cols={3} className="relative items-center">
        <SchemaFormLabel field={field} isFieldComplete={false} showRequiredDot={false} className="col-span-2 pb-0" />
        <FlexRow className="col-span-1 flex-grow">{buttonGroup}</FlexRow>
        {field.validators['required'] ? <RequiredDot success={isFieldComplete} className="-top-7" /> : null}
      </Grid>
    </div>
  ) : (
    <div className={twMerge(`mb-5 ${field.wrapperClasses}`)} key={field.key}>
      <SchemaFormLabel field={field} isFieldComplete={isFieldComplete} />
      {field.tileGrid ? (
        //
        // Layout used to place the reset button underneath the larger tile buttons.
        //
        <>
          {buttonGroup}
          {resetButton}
        </>
      ) : (
        //
        // Layout used to make it look like the reset button is one of the toggle options.
        //
        <Grid cols={3}>
          <FlexRow className="col-span-2">{buttonGroup}</FlexRow>
          <FlexRow className="col-span-1">{resetButton}</FlexRow>
        </Grid>
      )}
    </div>
  )
}

function fieldHasValue(value: unknown) {
  if (value == null) {
    return false
  }

  return Array.isArray(value) ? !!Array.from(value).length : true
}
