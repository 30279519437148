import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactFunctionalComponent } from '@unreserved-frontend-v2/utils/types'
import { createElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToggleButtonItem } from '../ui-toggle-button-group/ui-toggle-button-group'

function ToggleButtonIcon({
  icon,
  iconType = 'icon',
  className = '',
  iconClassName = 'p-3 text-[39px]',
  svgClassName = 'border px-3 pt-3 pb-2',
  dataTestId = '',
}: ToggleButtonItem) {
  return (
    <div className={className} data-testid={dataTestId}>
      {iconType === 'icon' ? (
        <FontAwesomeIcon className={iconClassName} icon={icon as IconProp} />
      ) : (
        <div className={svgClassName}>{createElement(icon as ReactFunctionalComponent)}</div>
      )}
    </div>
  )
}

export default ToggleButtonIcon
