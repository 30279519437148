import { twMerge } from 'tailwind-merge'

export interface FlexColProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  id?: string
  span?: number
  className?: string
  dataTestId?: string
  centered?: boolean
  children: React.ReactNode
}

export function FlexCol({ span, className = '', dataTestId = '', centered = false, children, ...rest }: FlexColProps) {
  return (
    <div
      className={twMerge(
        'flex flex-col',
        centered ? 'items-center justify-center' : '',
        className,
        span ? `col-span-${span}` : ''
      )}
      data-testid={dataTestId}
      {...rest}
    >
      {children || null}
    </div>
  )
}
